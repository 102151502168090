<div class="inline-flex">
  <div class="button-wrapper--outer" cdbaInView>
    <div class="button-wrapper--inner">
      <cdba-load-bom-button [gridApi]="gridApi"></cdba-load-bom-button>
    </div>
  </div>
  <div class="button-wrapper--outer" cdbaInView>
    <div class="button-wrapper--inner">
      <cdba-compare-button></cdba-compare-button>
      <cdba-excluded-calculations-button
        [excludedCalculations]="excludedCalculations$ | ngrxPush"
      ></cdba-excluded-calculations-button>
    </div>
  </div>
</div>
